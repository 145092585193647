<template>
	<a-layout id="components-layout-demo-custom-trigger">
		<a-layout-sider v-model="collapsed" :trigger="null" collapsible>
			<div class="logo" :class="{'wu': collapsed}">
				<span>钱呈无忧监控平台</span>
			</div>

			<a-menu theme="dark" mode="inline" :default-selected-keys="['111']" :default-open-keys="['sub1']">
				<a-menu-item key="1" @click="linkPage('/home')" v-if="isHome">
					<a-icon type="home" />
					<b class="titb" style="margin-left:4px">工作台</b>
				</a-menu-item>
				<a-sub-menu v-for="(data) in menus" :key="data.title">
					<span slot="title">
						<a-icon :type="data.icon" /> <b class="titb">{{data.title}}</b> </span>
					<a-menu-item @click="linkPage(item.url)" v-for="(item) in data.childers" :key="item.name"> {{item.name}}
					</a-menu-item>
				</a-sub-menu>
			</a-menu>
		</a-layout-sider>
		<a-layout>
			<a-layout-header style="background: #fff; padding: 0" class="layout-header">
				<a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="() => (collapsed = !collapsed)" />
				<a-dropdown>
					<div class="user-info">
						<div class="user-name">{{username}}</div>
						<div class="user-avater">
							<img src="../../assets/img.png" />
						</div>
					</div>
					<a-menu slot="overlay">
						<a-menu-item>
							<a @click="updatePassword">修改个人密码</a>
						</a-menu-item>
						<a-menu-item>
							<a @click="logut">退出登录</a>
						</a-menu-item>
					</a-menu>
				</a-dropdown>
			</a-layout-header>

			<a-layout-content style="overflow-y: auto;margin: 24px 16px; min-height: 280px;">
				<router-view />
			</a-layout-content>
		</a-layout>
	</a-layout>
</template>
<script>
	export default {
		data() {
			return {
				newTabIndex: 0,
				collapsed: false,
				menuIcons: {
					'监控': 'desktop',
					'交易': 'credit-card',
					'终端': 'database',
					'财务': 'calculator',
					'管理': 'schedule',
					'设置': 'setting',
				},
				isHome: false,
				menus: [],
				username: ''
			};
		},
		created() {
			let authority = JSON.parse(localStorage.getItem('authority'))
			let newAuthority = []
			authority.forEach((v) => {
				if (v.router == '/home') {
					this.isHome = true
				}
				if (v.router != '/home') {
					newAuthority.push(v)
				}
			})
			this.menus = newAuthority.map((v) => {
				return {
					title: v.router_name,
					icon: this.menuIcons[v.router_name],
					childers: v.list ? v.list.map((vi) => {
						return {
							url: vi.router,
							name: vi.router_name
						}
					}) : []
				}
			})
			this.username = localStorage.getItem('username')
		},
		methods: {
			onEdit() {},
			linkPage(url) {
				this.$router.push({
					path: url
				})
			},
			logut () {
				localStorage.removeItem('username')
				localStorage.removeItem('token')
				localStorage.removeItem('authority')
				this.$router.push({
					path: '/login'
				})
			},
			updatePassword () {
				this.$router.push({
					path: '/change-password'
				})
			}
		},
	};
</script>
<style lang="less">
	.ant-layout-sider {
		background-color: #fff !important;
		.ant-menu-dark {
			background-color: #fff !important;
			    color: rgba(0, 0, 0, 0.65) !important;
				.ant-menu-item {
					color: #000 !important;
				}
		}
		.ant-menu-dark .ant-menu-item-selected .anticon {
			color: #000 !important;
		}
		.ant-menu-submenu-title:hover {
			color: #000 !important;
		}
		.ant-menu-dark .ant-menu-submenu-open {
			color: #000 !important;
		}
		.ant-menu-dark .ant-menu-inline.ant-menu-sub {
			background-color: #fff !important;
			box-shadow: none;
		}
		.ant-menu.ant-menu-dark .ant-menu-item-selected {
			    background-color: #e6f7ff !important;
		}
		.ant-menu-submenu-arrow {
			&:after {
				 background: rgba(0, 0, 0, 0.65) !important;
			}
			&:before {
				 background: rgba(0, 0, 0, 0.65) !important;
			}
		}
		.ant-menu-submenu-selected {
			color: #000 !important;
			  
		}
		
	}
	.ant-menu-dark, .ant-menu-dark .ant-menu-sub {
		background-color: #fff !important;
	}
	.ant-menu-dark .ant-menu-item-selected {
		color: #000 !important;
		background-color: #e6f7ff !important;
	}
	.ant-menu-dark .ant-menu-item {
		  color: rgba(0, 0, 0, 0.65) !important;
	}
	#components-layout-demo-custom-trigger .trigger {
		font-size: 18px;
		line-height: 64px;
		padding: 0 24px;
		cursor: pointer;
		transition: color 0.3s;
	}

	#components-layout-demo-custom-trigger .trigger:hover {
		color: #1890ff;
	}

	#components-layout-demo-custom-trigger .logo {
		height: 32px;
		background: rgba(255, 255, 255, 0.2);
		margin: 10px 11px !important;
	}

	#components-layout-demo-custom-trigger {
		.logo {
			color: #fff;
			font-weight: 700;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 20px;
			background-color: transparent !important;
			background-image: url(../../assets/logo.png);
			background-position: left center;
			background-repeat: no-repeat;
			background-size: 24px 24px;
			width: 195px;
			height: 43px;
			color: #000;
			span {
				position: relative;
				left: 10px;
			}
			&.wu {
				width: 45px;
				position: relative;
				background-position: 17px center;
				span {
					display: none;
				}
			}
		}

		.header-tabs {
			margin: 24px 16px;
			margin-bottom: 0px;

			.ant-tabs-bar {
				margin-bottom: 0px;
			}
		}

		.layout-header {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.user-info {
				display: flex;
				padding-right: 20px;
				align-items: center;
				cursor: pointer;

				.user-name {
					margin-right: 10px;
				}

				.user-avater {
					width: 50px;
					height: 50px;
					background-color: #ccc;
					border-radius: 50px;
					img {
						display: block;
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}

	.ant-menu-dark.ant-menu-inline {
		overflow: auto;
		height: calc(100% - 65px);
	}

	/* 设置滚动条的样式 */
	.ant-menu-dark.ant-menu-inline::-webkit-scrollbar {
		width: 1px;
	}

	/* 滚动槽 */
	.ant-menu-dark.ant-menu-inline::-webkit-scrollbar-track {
		-webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.0);
		border-radius: 1px;
	}

	/* 滚动条滑块 */
	.ant-menu-dark.ant-menu-inline::-webkit-scrollbar-thumb {
		border-radius: 1px;
		background: rgba(0, 0, 0, 0.0);
		-webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
	}

	.ant-menu-dark.ant-menu-inline::-webkit-scrollbar-thumb:window-inactive {
		background: rgba(255, 0, 0, 0.0);
	}
	
	
</style>
